import { useEffect, useMemo, useState } from 'react'
import { GridApi, GridOptions, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community'
import trans from '../../../trans'
import dateFormatter from '../../AgGrid/ValueFormatter/dateFormatter'
import { amountFormatter } from '../../AgGrid/ValueFormatter/amountFormatter'
import defaultFormatter from '../../AgGrid/ValueFormatter/defaultFormatter'
import { Account, IAccount } from '../../../types/account'
import ibanFormatter from '../../AgGrid/ValueFormatter/ibanFormatter'

function bankAccountStatusFormatter(params: ValueFormatterParams) {
  if (params.value === 'ACTIVE') {
    return trans('accounts.accounts_status_active')
  }

  return trans('accounts.accounts_status_not_active')
}

export function useGridOptions() {
  const [api, setApi] = useState<GridApi>(null)
  const [gridOptions, setGridOptions] = useState<GridOptions<IAccount>>({
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressSizeToFit: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    enableBrowserTooltips: true,
    onGridReady(event: GridReadyEvent<IAccount>) {
      setApi(event.api)
    },
    columnDefs: [
      {
        headerName: trans('accounts.accounts_th_bank_name'),
        field: 'bank_name',
        valueFormatter: defaultFormatter,
        minWidth: 250,
      },
      {
        headerName: trans('accounts.accounts_th_iban'),
        field: 'iban',
        valueFormatter: ibanFormatter,
        minWidth: 300,
        maxWidth: 300,
      },
      {
        headerName: trans('accounts.accounts_th_currency'),
        field: 'currency',
        valueFormatter: defaultFormatter,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: trans('accounts.accounts_th_status'),
        field: 'status',
        valueFormatter: bankAccountStatusFormatter,
        minWidth: 120,
        maxWidth: 120,
      },
    ],
  })

  const onResize = useMemo(() => {
    return () => {
      if (api) {
        api.sizeColumnsToFit()
      }
    }
  }, [api])

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [api])

  return gridOptions
}
